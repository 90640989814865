import { Container, HeaderLogo, HeaderTitle } from "./styles";
import { MdLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";

interface props {
  label: string;
}

export const Header = ({ label }: props) => {
  const navigate = useNavigate();
  const logoff = () => {
    localStorage.removeItem(process.env.REACT_APP_JWT_NAME || "");

    navigate("/");
  };

  return (
    <Container>
      <header>
        <HeaderLogo>
          <img src="logo.png" alt="logo" />
        </HeaderLogo>
        <HeaderTitle>
          <span></span>
          <span style={{ display: "flex", justifyContent: "center" }}>
            {label}
          </span>
          <span
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: "16px",
              paddingTop: "0px",
            }}
          >
            <MdLogout
              size={24}
              color={"white"}
              onClick={logoff}
              style={{ cursor: "pointer" }}
            />
          </span>
        </HeaderTitle>
      </header>
    </Container>
  );
};
