import { useState, useEffect, useRef } from "react";
import { Header } from "../../components/Header";
import { MdCheckCircle } from "react-icons/md";
import { MainContainer } from "./styles";
import { Button, Form } from "react-bootstrap";
import ReactLoading from "react-loading";
import "../../index.css";
import { api } from "../../services/api";
import { handleKeyDown } from "../../utils/keyMap";
import CustomButton from "../../components/Buttom/Index";

export const Home = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any[] | undefined>();
  const [codeDock, setCodeDock] = useState("");
  const [codePallet, setCodePallet] = useState("");

  const getData = async () => {
    setLoading(true);

    const response = await api.get(`wms/dock?CODDOCA=${codeDock}`);

    if (response?.data) {
      setData(response?.data);
      // console.log(response.data);
      // setData([...response?.data, { ...response?.data[0], NROPALETE: 5 }]);
    }
    setLoading(false);
  };
  const [loadingConclude, setLoadingConclude] = useState(false);

  const handleConclude = async () => {
    try {
      setLoadingConclude(true);
      data?.forEach(async (element) => {
        if (element.SELECTED === "S")
          await api.put(`wms/dock/conclude`, { ...element, AD_STATUSDOCA: 1 });
      });
      alert("Finalizado com sucesso!");
      setLoadingConclude(false);
    } catch (error) {
      console.log(error);
      setLoadingConclude(false);
    }
  };

  const handleClick = async () => {
    if (!(Number(codeDock) > 0)) return;
    await getData();
    inputRef.current?.focus();
  };

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  const updateItem = async (value: any) => {
    await api.post(`wms/dock/update`, {
      ...value,
      CODUSU: 91,
    });
    // console.log(teste);
  };

  const handlePalletClick = async () => {
    setData((prevState) =>
      prevState?.map((item) => {
        if (item.NROPALETE === Number(codePallet.slice(-3))) {
          updateItem(item);
          return { ...item, SELECTED: "S" };
        }
        return item;
      })
    );
    setCodePallet("");
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: "5",
        height: "100vh",
        width: "100%",
      }}
    >
      <Header label={`WMS`} />
      {loading && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <ReactLoading
            type={"cylon"}
            color={"#ff8000"}
            height={"150px"}
            width={"150px"}
          />
        </div>
      )}
      {!loading && (
        <MainContainer>
          {data && (
            <div>
              <div style={{ marginBottom: "5px" }}>
                <Form.Group
                  controlId="idPallet"
                  style={{
                    display: "flex",
                    gap: 5,
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    <Form.Label>Pallet</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Informe o Pallet"
                      value={codePallet}
                      onKeyDown={(event) =>
                        handleKeyDown(event, handlePalletClick)
                      }
                      autoFocus
                      ref={inputRef}
                      onChange={(e) => setCodePallet(e.target.value)}
                    />
                  </div>
                  <div style={{ display: "flex", gap: 5 }}>
                    <Button variant="primary" onClick={handlePalletClick}>
                      OK
                    </Button>
                    <Button
                      variant="secondary"
                      onClick={() => setData(undefined)}
                    >
                      Voltar
                    </Button>
                    <CustomButton
                      variant="success"
                      onClick={handleConclude}
                      isLoading={loadingConclude}
                    >
                      Finalizar
                    </CustomButton>
                  </div>
                </Form.Group>
              </div>
              <div>
                <div style={{ display: "flex", width: "100%" }}>
                  <div style={{ width: "20px", marginRight: "5px" }}></div>
                  <div style={{ width: "50px", marginRight: "5px" }}>
                    Pallet
                  </div>
                  <div style={{ width: "60px", marginRight: "5px" }}>
                    O.Carga
                  </div>
                  <div style={{ width: "70px", marginRight: "5px" }}>
                    Situação
                  </div>
                  <div style={{ width: "150px" }}>Observação</div>
                </div>
              </div>
              <div>
                {data
                  ?.filter((item) => item?.SELECTED === "S")
                  ?.map((item, idx) => (
                    <div style={{ display: "flex", width: "100%" }} key={idx}>
                      <div style={{ width: "20px", marginRight: "5px" }}>
                        {item?.SELECTED === "S" && (
                          <MdCheckCircle color="green" />
                        )}
                      </div>
                      <div style={{ width: "50px", marginRight: "5px" }}>
                        {item.NROPALETE}
                      </div>
                      <div style={{ width: "60px", marginRight: "5px" }}>
                        {item.ORDEMCARGA}
                      </div>
                      <div style={{ width: "70px", marginRight: "5px" }}>
                        {item.SITUACAO}
                      </div>
                      <div style={{ width: "150px", marginRight: "5px" }}>
                        {item.OBSERVACAO}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )}
          {!data && (
            <div>
              <Form.Group
                controlId="idDock"
                style={{
                  display: "flex",
                  gap: 5,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Form.Label>Doca</Form.Label>
                <Form.Control
                  type="text"
                  autoFocus
                  placeholder="Informe a Doca"
                  value={codeDock}
                  onKeyDown={(event) => handleKeyDown(event, handleClick)}
                  onChange={(e) => setCodeDock(e.target.value)}
                />
                <Button variant="primary" onClick={handleClick}>
                  OK
                </Button>
              </Form.Group>
            </div>
          )}
        </MainContainer>
      )}
    </div>
  );
};
