import React from "react";
import { Button, Spinner, ButtonProps } from "react-bootstrap";

interface CustomButtonProps extends ButtonProps {
  isLoading?: boolean;
}

export const CustomButton = ({
  isLoading = false,
  children,
  ...props
}: CustomButtonProps) => {
  return (
    <Button size={"sm"} {...props} disabled={isLoading || props?.disabled}>
      {isLoading ? (
        <>
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className="me-2"
          />
          Loading...
        </>
      ) : (
        children
      )}
    </Button>
  );
};

export default CustomButton;
